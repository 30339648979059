/* You can add global styles to this file, and also import other style files */
// @import "assets/themes/primeng.css";
// @import "assets/themes/flags.css";
@import "assets/themes/app/app.scss";

.p-fileupload-row {
  display: none !important;
}

.p-button {
  background: #ea5b0c !important;
  border-color: #ea5b0c !important;;
}

.p-button:active {
  box-shadow: none;
}

.tooltip-style {
  max-width: 100% !important;
}

.dropdown-column {
  overflow: visible !important;
}

.p-message .p-message-close {
  background: #155724 !important;
}

.badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-green {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-red {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-yellow {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-grey {
    background: #cecece;
    color: #282828;
  }
}

.p-paginator-current {
  background-color: #ea5b0c !important;
  color: #ffffff !important;
}
