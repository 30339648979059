.progress-spinner {
  position: fixed;
  z-index: 999;
  height: 1em;
  width: 1em;
  overflow: show;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 5%;
}

.layout-content {
  margin-left: 250px;

  .content-section {
    padding: 2rem;

    &.introduction {
      background-color: var(--surface-b);
      color: var(--text-color);
      padding-bottom: 0;
      display: flex;
      align-items: top;
      justify-content: space-between;

      .feature-intro {
        h1 {
          span {
            font-weight: 400;
            color: var(--text-color-secondary);
          }
        }

        p {
          margin: 0;
        }

        a {
          text-decoration: none;
          color: $linkColor;
          font-weight: 600;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      app-demoActions {
        display: flex;
        justify-content: flex-end;

        .p-button-icon-only {
          padding: 0.5rem 0;
        }
      }

      .app-inputstyleswitch {
        margin-left: 2rem;
      }
    }

    &.implementation {
      background-color: var(--surface-b);
      color: var(--text-color);

      + .documentation {
        padding-top: 0;
      }
    }

    &.documentation {
      background-color: var(--surface-b);
      color: var(--text-color);

      li {
        line-height: 1.5;
      }

      a {
        text-decoration: none;
        color: $linkColor;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }

      .doc-tablewrapper {
        margin: 1rem 0;
        overflow: auto;
      }

      i:not([class~='pi']) {
        background-color: var(--surface-a);
        color: #2196f3;
        font-family: Monaco, courier, monospace;
        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 4px;
        letter-spacing: 0.5px;
        border-radius: 3px;
        font-weight: 600;
        margin: 0 2px;
      }

      .p-tabview {
        background: transparent;
        border: 0 none;

        .p-tabview-nav {
          border-radius: 0;
          padding: 0;
          border-bottom: 1px solid var(--surface-d);
          background-color: transparent;

          li {
            margin-right: 0;
            border: 0 none;
            top: 1px;
            line-height: 1;

            a,
            a:visited {
              color: var(--text-color-secondary);
              text-shadow: none;
              height: inherit;
              background-color: transparent;
              border: 0 none;
              border-bottom: 1px solid transparent;
              margin-bottom: -1px;
              transition: border-bottom-color 0.2s;

              &:focus {
                @include focused();
              }

              &:hover {
                background: transparent;
                text-decoration: none;
              }
            }

            &.p-highlight a,
            &.p-highlight:hover a {
              background: transparent;
              color: var(--primary-color);
              border-bottom: 1px solid var(--primary-color);
            }

            &:not(.p-highlight):not(.p-disabled):hover a {
              color: var(--text-color);
              border-bottom: 1px solid var(--primary-color);
            }
          }

          .p-tabview-ink-bar {
            display: none;
          }
        }

        .p-tabview-panels {
          background: transparent;
          border: 0 none;
          padding: 2rem 1rem;

          .btn-viewsource {
            display: inline-block;
            padding: 0.5rem 1rem;
          }

          a {
            text-decoration: none;
            color: $linkColor;
            font-weight: 600;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .doc-table {
      border-collapse: collapse;
      width: 100%;
      background-color: var(--surface-a);

      th {
        border-bottom: 1px solid var(--surface-d);
        padding: 1rem;
        text-align: left;
      }

      tbody {
        td {
          padding: 1rem;
          border-bottom: 1px solid var(--surface-d);

          &:first-child {
            font-family: 'Courier New', monospace;
            font-weight: bold;
            color: var(--text-color-secondary);
          }
        }
      }

      &.browsers {
        td {
          &:first-child {
            font-family: var(--font-family);
            font-weight: normal;
            color: var(--text-color);
          }
        }
      }
    }
  }

  .card {
    background: var(--surface-e);
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 2rem;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .fluid-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.p-datatable .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

button {
  border: none;
  color: white;
  padding: 9px 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 0.9em;
  margin-right: 0.9em;
  cursor: pointer;
  background-color: #ea5b0c !important;
}

button:active {
  color: black;
  border: none;
}

button:hover {
  background: gray;
  color: black;
}

button {
  color: white !important;
  background: #ea5b0c !important;
}

.action-btn {
  border-radius: 4px;
}
